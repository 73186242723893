<template>
	<div class='my-customer'>
		<div class="header acea-row row-between-wrapper" ref="header">
		    <div class="black" @click="returnChange()">
		        <img src="@assets/images/icon_black.png" alt="" />
		    </div>
		    <p>我的生产订制</p>
	    </div>	
	    <div class="menu acea-row row-left">
	    	<div class="item" :class="check===item.id? 'on':''" v-for="(item,index) in menuData" :key="index" @click="bindCheckChange(item.id)">
	    		<p>{{item.title}}</p>
	    	</div>
	    </div>
	    <div class="list-content">
	    	<div class="list acea-row row-between" v-for="(item,index) in listData" :key="index">
	    		<div class="left acea-row row-center-wrapper">
	    			<!-- <img :src="item.purchase_image"> -->
	    			<p>订制</p>
	    		</div>
	    		<div class="right">
	    			<p class="title acea-row row-right">生产订制</p>
	    			<p class="acea-row row-right">提交时间：{{item.create_time}}</p>
	    			<p class="acea-row row-right">编号：{{item.order_sn}}</p>
	    			<div class="acea-row row-between">
	    				<p>状态：<span v-if="check==0">待审核</span><span v-if="check==1">已审核</span><span v-if="check==3">不通过</span></p>
	    				<p>
	    					<span class="see" @click="getVideLink(item.purchase_image)">查看采购订单</span>
	    				</p>
	    			</div>
	    		</div>
	    	</div>
	    </div>
	</div>
</template>
<script type="text/javascript">
import { getMyCustomer } from '@api/user';
export default {
	name:'MyCustomer',
	data(){
		return {
			menuData:[
				{id:'',title:'全部'},
				{id:0,title:'待审核'},
				{id:1,title:'已审核'},
				{id:2,title:'未通过'},
			],
			check:'',
			listData:'',
			images:'',
			show:false,
		}
	},
	watch: {
	    $route(n) {
	      if (n.name === 'MyCustomer') this.getListData();
	    }
	},
	mounted(){
		this.getListData();
	},
	methods:{
		//返回上一页
		returnChange() {
	      var that = this;
	      that.$router.go(-1);
	    },
	    //点击查看数
	    getVideLink(path){
	    	var that = this;
	    	if(!path) {
	    		this.$dialog.error('未上传数据文件');
        		return false;
	    	}
	      	that.$router.push({ path: '/images?path=' + path });
	      // window.open(path, "_blank");
	    },
	    //点击菜单
		bindCheckChange(id){
			this.check = id;
		},
		getListData(){
			var data = {check:this.check,};
			getMyCustomer(data).then(res=>{
				this.listData = res.data;
			}).catch(res=>{
				console.log(res);
			})
		},
	}
}
</script>

<style type="text/css">
.my-customer { padding-top:50px; min-height:100%; background:#EEEEEE!important; }

.my-customer.router { background:#fff }
.my-customer .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.my-customer .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.my-customer .header .black img {
  width: 100%;
  height: 100%;
}
.my-customer .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}	
</style>
